import React from 'react';
import mainBg from "../../images/hero.webp";
import {Col, Grid, Row} from "react-flexbox-grid";

const HeroImage = ({ title, description, backgroundImage = mainBg}) => {
    return (
        <div className="middle-footer" style={{ backgroundImage: `linear-gradient(180deg, rgba(65, 74, 133, 1), rgba(65, 74, 133, 0)), url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className="container" >
                <Grid fluid>
                    <Row style={{paddingTop: "250px", paddingLeft: "10px"}}>
                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                    </Row>
                    <Row style={{paddingBottom: "180px", paddingLeft: "30px"}}>
                        <Col lg={3}>
                            <h4 style={{color: "white"}}>{ title }</h4>
                            <h1 style={{color: "white"}}>{description}</h1>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default HeroImage;
