import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import {useMediaQuery} from "react-responsive";

const ContactSections = () => {

    const matches = useMediaQuery({ query: '(min-width: 992px)' });
    const matches1 = useMediaQuery({ query: '(min-width: 590px)' });

    return (
        <div className="middle-footer " style={{ marginTop: "-140px", paddingBottom: "160px"}}>
            {/*<div className="container" >*/}
            {/*    <Grid fluid>*/}
            {/*        <Row style={{ paddingTop: "50px", paddingBottom: "10px"}}>*/}
            {/*            <Col lg={4}>*/}
            {/*                <div className="gradient-top-to-bottom" style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>*/}
            {/*                    <Row >*/}
            {/*                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>*/}
            {/*                    </Row>*/}
            {/*                    <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
            {/*                        <Col lg={8}>*/}
            {/*                            <h3 >Contact 1</h3>*/}
            {/*                            <span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>*/}
            {/*                        </Col>*/}
            {/*                    </Row>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*            <Col lg={4}>*/}
            {/*                <div className="gradient-top-to-bottom" style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>*/}
            {/*                    <Row >*/}
            {/*                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>*/}
            {/*                    </Row>*/}
            {/*                    <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
            {/*                        <Col lg={8}>*/}
            {/*                            <h3 >Contact 1</h3>*/}
            {/*                            <span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>*/}
            {/*                        </Col>*/}
            {/*                    </Row>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*            <Col lg={4}>*/}
            {/*                <div className="gradient-top-to-bottom" style={{ margin: "10px", padding: "30px", height: matches1 ? "20vh" : "auto"}}>*/}
            {/*                    <Row >*/}
            {/*                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>*/}
            {/*                    </Row>*/}
            {/*                    <Row style={{ textAlign: matches ? "left" : "center"}}>*/}
            {/*                        <Col lg={8}>*/}
            {/*                            <h3 >Contact 1</h3>*/}
            {/*                            <span> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span>*/}
            {/*                        </Col>*/}
            {/*                    </Row>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Grid>*/}
            {/*</div>*/}
        </div>
    );
};

export default ContactSections;
