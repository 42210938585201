import React, {useEffect, useState} from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import {styled} from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import {ReactComponent as Logo} from '../../svg/logo.svg';
import {ReactComponent as LogoBlack} from '../../svg/logo_black.svg';
import {IconButton} from "@mui/material";

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {useMediaQuery} from "react-responsive";
import {Link, useLocation} from "react-router-dom";


const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#BEB0FF',
    },
});



const Menu = ({menuWhiteTheme}) => {


    const [bgColor, setBgColor] = useState('none');
    const [paddingSize, setPaddingSize] = useState('50px');

    let listenScrollEvent = e => {
        let contact = window.location.pathname.split("/").pop() === "contact" ? true : false;

        if (window.scrollY > 10
        ) {
            setBgColor(contact ? '#fff' : '#090909');
            setPaddingSize('18px')
        } else {
            setBgColor('transparent');
            setPaddingSize('50px');
        }

    }

    useEffect(() => {
        console.log(menuWhiteTheme);
        window.addEventListener('scroll', listenScrollEvent)
       return () => {
            window.removeEventListener('scroll', listenScrollEvent)
       }
    }, []);

    const [value, setValue] = useState(0);

    const matches = useMediaQuery({ query: '(min-width: 992px)' });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const location = useLocation();

    useEffect(() => {
        // Determine the current path and set the default value
        switch (location.pathname) {
            case '/':
                setValue(0);
                break;
            case '/key_features':
                setValue(1);
                break;
            case '/why_us':
                setValue(2);
                break;
            case '/team':
                setValue(3);
                break;
            case '/contact':
                setValue(4);
                break;
            default:
                setValue(0); // Default to "Home" if path is unknown
        }
    }, [location.pathname]); // Update whenever the pathname changes


    const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            color: menuWhiteTheme ?  'rgba(255, 255, 255, 0.7)' : "#595959" ,
            '&.Mui-selected': {
                color: menuWhiteTheme ? '#fff !important' : "black !important",
            },
            '&.Mui-focusVisible': {
                backgroundColor: '#BEB0FF',
                color: "#BEB0FF"
            },
        }),
    );

    return (
        <div className="menu" style={{
            position: "fixed", zIndex: "1000", width: "100%",
            paddingTop: paddingSize, backgroundColor: bgColor,
            transform: "scale(1)",
            transition: "0.15s all ease"
        }}>
            <div className="container">
                <Grid fluid>
                    <Row>
                        <Col lg={5} >
                            <Link to="/">
                                { menuWhiteTheme ?
                                <Logo style={{ width: "30vh"}}/>
                                    :
                                    <LogoBlack style={{ width: "30vh"}}/>
                            }
                            </Link>
                        </Col>
                        <Col lg={7} style={{display: matches ? "block" : "none"}}>
                            <Box sx={{ width: '100%', marginLeft: "30px" }}>
                                <Box>
                                    <StyledTabs

                                        value={value}
                                        onChange={handleChange}
                                        aria-label="styled tabs example"
                                    >
                                            <StyledTab label="Home" index={0} component={Link} to={"/"}/>
                                            <StyledTab label="Key Features" index={1} component={Link} to={"/key_features"} />
                                            <StyledTab label="Why us" index={2} component={Link} to={"/why_us"}/>
                                            <StyledTab label="Team" index={3} component={Link} to={"/team"}/>
                                            <StyledTab label="Contact" index={4} component={Link} to={"/contact"}/>

                                      <Link to="https://www.facebook.com/houndbytes" target={"_blank"}>
                                        <IconButton aria-label="delete">
                                            <FacebookIcon style={{ color: menuWhiteTheme ? "white" : "black"}} />
                                        </IconButton>
                                      </Link>

                                        {/*<IconButton aria-label="delete" url>*/}
                                        {/*    <TwitterIcon style={{ color: menuWhiteTheme ? "white" : "black"}} />*/}
                                        {/*</IconButton>*/}

                                        <Link to={"https://www.linkedin.com/company/houndbytes/"} target={"_blank"}>
                                          <IconButton aria-label="delete">
                                              <LinkedInIcon style={{ color: menuWhiteTheme ? "white" : "black"}} />
                                          </IconButton>
                                        </Link>
                                    </StyledTabs>
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default Menu;

