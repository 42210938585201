import React, { Fragment, useEffect } from 'react'
import Menu from '../components/menu/Menu'
import MenuBottom from '../components/menu/MenuBottom'
import Hero from '../components/hero/Hero'
import Footer from '../components/footer/Footer'
import { Col, Grid, Row } from 'react-flexbox-grid'

const PrivacyPolicy = ({ setMenuWhiteTheme }) => {

  useEffect(() => {
    setMenuWhiteTheme(true)
  }, [])

  return (
    <Fragment>

      <MenuBottom/>
      <Hero/>

      <div className="middle-footer">
        <div className="container">
          <Grid fluid>
            <Row style={{ paddingTop: '70px' }}>
              <div style={{ width: '30px', height: '10px', backgroundColor: '#BEB0FF' }}></div>
            </Row>
            <Row>
              <Col lg={8}>
                <h1>Privacy Policy</h1>
              </Col>
            </Row>
            <Row style={{ paddingBottom: '100px' }}>
              <Col lg={8}>
                <h2 className="text-black fs-3 fw-bolder mb-5">
                  Privacy Statement
                </h2>
                <p>
                  HoundBytes ("we," "us," or "our") is committed to protecting the privacy of our customers and users ("you,"
                  "your," or "users") when they
                  use our Security Operation Center as a Service ("SOCaaS" or "Service") and visit our website ("Site").
                  This Privacy Policy (the "Policy") explains how we collect, use, and disclose information about you and your use
                  of our Service and Site.
                </p>

                <h3 className="pt-5">
                  <span className="text-purple">1. Information We Collect</span>
                </h3>
                <p>We may collect information about you from a variety of sources, including:</p>
                <ul>
                  <li>
                    Information you provide to us: We may collect information from you when you create an account, purchase a
                    subscription, or use our Service.
                    This information may include your name, email address, phone number, and other contact information.
                  </li>
                  <li>
                    Information we collect automatically: When you use our Service or visit our Site, we may automatically collect
                    information about your
                    device and usage of the Service, including IP address, browser type, operating system, usage data, and other
                    technical information.
                  </li>
                  <li>
                    Information from third parties: We may receive information about you from third-party sources such as social
                    media platforms,
                    marketing and advertising partners, and data brokers.
                  </li>
                </ul>


                <h3 className="pt-5">
                  <span className="text-purple">2. How We Use Information</span>
                </h3>
                <p>We may use the information we collect about you to:</p>
                <ul>
                  <li>
                    Provide and improve our Service and Site;
                  </li>
                  <li>
                    Communicate with you about your account, subscriptions, and other services;
                  </li>
                  <li>
                    Send you promotional materials, marketing and advertising;
                  </li>
                  <li>
                    Create and maintain customer accounts;
                  </li>
                  <li>
                    Create and maintain customer profiles;
                  </li>
                  <li>
                    To comply with legal requirements, including complying with court orders, valid discovery requests, valid
                    subpoenas, and other appropriate legal mechanisms.
                  </li>
                </ul>

                <h3 className="pt-5">
                  <span className="text-purple">3. Sharing and Disclosure of Information</span>
                </h3>
                <p>We may share and disclose your information as follows:</p>
                <ul>
                  <li>
                    With third-party service providers: We may share your information with third-party service providers that
                    perform services on our behalf, such as hosting and maintenance of the Service, analytics, marketing and
                    advertising, and payment processing.
                  </li>
                  <li>
                    With business partners: We may share your information with business partners for marketing and advertising
                    purposes, and to provide the SOCaaS services to our partners.
                  </li>
                  <li>
                    As required by law: We may disclose your information as required by law, such as in response to a subpoena or
                    court order, or to protect the rights and safety of our users, our company, and the public.
                  </li>
                  <li>
                    With your consent: We may share or disclose your information with your consent.
                  </li>
                </ul>


                <h3 className="pt-5">
                  <span className="text-purple">4. Data Retention</span>
                </h3>
                <p>We will retain your information for as long as your account is active, or as needed to provide you with the Service
                  or as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>

                <h3 className="pt-5">
                  <span className="text-purple">5. Security</span>
                </h3>
                <p>
                  We take the security of your information seriously and take reasonable measures to protect your information from
                  unauthorized access, use, alteration, or destruction. However, no security system is impenetrable, and we cannot
                  guarantee the security of our systems or the data we collect.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              6. International Data Transfers
            </span>
                </h3>
                <p>
                  Your information may be transferred to, and maintained on, computers located outside of your state, province,
                  country, or other governmental jurisdiction where the data protection laws may differ than those from your
                  jurisdiction.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              7. Your Choices
            </span>
                </h3>
                <p>
                  You have the following choices regarding the information we collect and use:
                </p>
                <ul>
                  <li>
                    You can choose not to provide certain information, but then you may not be able to use all of the features of
                    the Service or Site.
                  </li>
                  <li>
                    You can opt-out of receiving promotional and marketing materials from us by following the unsubscribe
                    instructions included in the
                    materials or by contacting us directly.
                  </li>
                  <li>
                    You can request access to, deletion of, or correction of your personal information by contacting us using the
                    contact information
                    provided at the end of this Policy.
                  </li>
                </ul>


                <h3 className="pt-5">
            <span className="text-purple">
              8. Data Subject Rights
            </span>
                </h3>
                <p>
                  You have the right to request access to your personal data, rectification of any inaccuracies, erasure of your
                  personal data, and the right to object to the processing of your data. You also have the right to request a copy
                  of your personal data in a structured, machine-readable format and the right to have your data transferred to
                  another controller (data portability).
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              9. Cookies
            </span>
                </h3>
                <p>
                  We may use cookies, web beacons, and similar technologies to collect information about your use of the Site and
                  Service. We may use this information to personalize your experience, to understand usage patterns, and to improve
                  the Service. Most web browsers allow you to control cookies through their settings.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              10. Contact Us
            </span>
                </h3>
                <p>
                  If you have any questions about this Policy or our privacy practices, please contact us at privacy@houndbytes.io
                  or HoundBytes, 208 B Splaiul Independentei, 42, Bucharest, Ilfov 060025, ROMANIA.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              11. Changes to this Policy
            </span>
                </h3>
                <p>
                  We may update this Policy from time to time to reflect changes in our practices or for other operational, legal,
                  or regulatory reasons. If we make changes, we will provide notice of such changes, such as by sending an email
                  notification or by posting the updated Policy on our Site and updating the "Effective Date" at the top of this
                  Policy. Your continued use of the Service or Site after any such changes constitutes your acceptance of the new
                  Policy. We encourage you to periodically review this Policy for the latest information on our privacy practices.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              12. Compliance
            </span>
                </h3>
                <p>
                  As a global company, HoundBytes is committed to complying with all applicable data protection laws, including the
                  General Data Protection Regulation (GDPR) in the European Union, the California Consumer Privacy Act (CCPA) in
                  California, USA, and any other similar or successor legislation in other regions where we operate. In case of any
                  questions or concerns regarding your rights under data protection laws specific to your region, please contact us
                  using the contact information provided at the end of this Policy.
                </p>
                <p>
                  By using the Service or visiting our Site, you are accepting the practices described in this Policy. If you do not
                  agree to the terms of this Policy, you should not use the Service or Site.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              13. Children's Privacy
            </span>
                </h3>
                <p>
                  Our Service and Site are not intended for children under the age of 18.
                  We do not knowingly collect personal information from children under the age of 18.
                  If we become aware that we have collected personal information from a child under the age of 18,
                  we will take steps to delete such information as soon as possible.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              14. California Privacy Rights
            </span>
                </h3>
                <p>
                  California residents have the right to request certain information about our data collection practices and to
                  opt-out of certain sharing of data with third parties. If you are a California resident, you may make such a
                  request by contacting us at privacy@houndbytes.io or by mail at HoundBytes, 208 B Splaiul Independentei, 42,
                  Bucharest, Ilfov 060025, ROMANIA.
                </p>
                <p>
                  This Privacy Policy is intended to comply with the California Consumer Privacy Act (CCPA) and any similar or
                  successor legislation.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              15. EU-US Privacy Shield
            </span>
                </h3>
                <p>
                  We adhere to the EU-US Privacy Shield Framework as set forth by the US Department of Commerce regarding the
                  collection, use, and retention of personal data from European Union member countries. We have certified that we
                  adhere to the Privacy Shield Principles of Notice, Choice, Accountability for Onward Transfer, Security, Data
                  Integrity and Purpose Limitation, Access, and Recourse, Enforcement, and Liability.
                </p>


                <h3 className="pt-5">
            <span className="text-purple">
              16. GDPR Compliance
            </span>
                </h3>
                <p>
                  We take the protection of your personal data very seriously and we comply with the data protection regulations
                  according to GDPR. In case of any questions or concerns regarding your rights under GDPR please contact us using
                  the contact information provided at the end of this Policy.
                </p>
                <p>
                  By using our Service and Site, you acknowledge that you have read and understand this Policy and that you agree to
                  be bound by its terms.
                </p>

              </Col>
            </Row>
          </Grid>
        </div>
      </div>


      <Footer/>
    </Fragment>
  )
}

export default PrivacyPolicy
