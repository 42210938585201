import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import mainBg from '../../images/lets-talk.webp';

const LetsTalk = () => {
    return (
        <div className="middle-footer" style={{ backgroundImage: `linear-gradient(180deg, rgba(65, 74, 133, 1), rgba(65, 74, 133, 0)), url(${mainBg})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className="container" >
                <Grid fluid>
                    <Row style={{paddingTop: "70px", paddingLeft: "14px"}}>
                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                    </Row>
                    <Row style={{paddingBottom: "180px", paddingLeft: "25px"}}>
                        <Col lg={8}>
                            <h1 style={{color: "white"}}>Don’t know which <span className="text-purple">best fits</span></h1>
                            <h1 style={{color: "white", marginTop: "-10px"}}>your needs?</h1>
                            <a target="_blank" href="https://www.linkedin.com/company/houndbytes/">
                                <button className={"action-button"}> LET'S TALK </button>
                            </a>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default LetsTalk;
