import React, {useEffect} from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import "./Hero.css";
// import mainBg from '../../images/hero.webp';
import mainBg from '../../images/hero-banner.webp';
import {Col, Grid, Row} from "react-flexbox-grid";
import {useMediaQuery} from "react-responsive";
import { Link } from 'react-router-dom'

const Hero = () => {

    const matches = useMediaQuery({query: '(min-width: 992px)'});


    useEffect(() => {
        const a1 = document.getElementsByClassName('awssld')[0]
        const a2 = document.getElementsByClassName('awssld__bullets')[0]
        const div = document.createElement("div");
        div.setAttribute('class', 'container container-bullets');
        a1.appendChild(div);
        const a3 = document.getElementsByClassName('container-bullets')[0]
        a3.appendChild(a2);
    }, []);

    return (
        <AwesomeSlider className={"hero"}>

            <div style={{ backgroundImage: ` linear-gradient(180deg, rgba(20,20,20, 0.65), rgba(20, 20, 20, 0.65)),  url(${mainBg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center right",
                backgroundColor: "#121212"
            }} className="container">
                <div style={{paddingTop: "35vh"}}>
                    <Grid fluid>
                        <Row>
                            <Col lg={4} style={{paddingLeft: matches ? "30px" : "15px"}}>
                                <h1 style={{ color: "white"}}> All-in-One MDR: SOC, SIEM, EDR, and Security Engineering Support</h1>
                                <Link to={"/key_features"}>
                                    <button className={"action-button"}> SEE DETAILS</button>
                                </Link>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>

            {/*<div style={{ backgroundImage: ` linear-gradient(180deg, rgba(20,20,20, 1), rgba(20, 20, 20, 0.2), rgba(255, 255, 255, 0)),  url(${mainBg})`,*/}
            {/*    backgroundSize: "cover",*/}
            {/*    backgroundRepeat: "no-repeat",*/}
            {/*    backgroundPosition: "center",*/}
            {/*    backgroundColor: "#121212"*/}
            {/*}} className="container">*/}
            {/*    <div style={{paddingTop: "35vh"}}>*/}
            {/*        <Grid fluid>*/}
            {/*            <Row>*/}
            {/*                <Col lg={4} style={{paddingLeft: matches ? "30px" : "15px"}}>*/}
            {/*                    <h1 style={{ color: "white"}}> Free assesment lorem ipsum dolor sit amen des unos ipsumusul lis</h1>*/}
            {/*                    <button className={"action-button"}> SEE DETAILS</button>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Grid>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div style={{ backgroundImage: ` linear-gradient(180deg, rgba(20,20,20, 1), rgba(20, 20, 20, 0.2), rgba(255, 255, 255, 0)),  url(${mainBg})`,*/}
            {/*    backgroundSize: "cover",*/}
            {/*    backgroundRepeat: "no-repeat",*/}
            {/*    backgroundPosition: "center",*/}
            {/*    backgroundColor: "#121212"*/}
            {/*}} className="container">*/}
            {/*    <div style={{paddingTop: "35vh"}}>*/}
            {/*        <Grid fluid>*/}
            {/*            <Row>*/}
            {/*                <Col lg={4} style={{paddingLeft: matches ? "30px" : "15px"}}>*/}
            {/*                    <h1 style={{ color: "white"}}> Free assesment lorem ipsum dolor sit amen des unos ipsumusul lis</h1>*/}
            {/*                    <button className={"action-button"}> SEE DETAILS</button>*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Grid>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </AwesomeSlider>
    );
};

export default Hero;
