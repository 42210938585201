import { init as initApm } from '@elastic/apm-rum'

var apm = initApm({
  serviceName: 'website',
  serverUrl: 'https://534ec919d875476786652febd5e18b10.apm.francecentral.azure.elastic-cloud.com:443',
  serviceVersion: '0.0.1',
  environment: 'production'
})

export default apm
