import React from 'react';
import { Col, Grid, Row } from "react-flexbox-grid";

import './TeamFull.css'; // Import the CSS file

const TeamFull = () => {
    const teamMembers = [
        {
            name: 'Marius Corici',
            title: 'CEO & Co-founder',
            image: 'images/marius_corici.jpeg',
            description: 'Marius Corici, based in Cluj-Napoca, CJ, RO, is currently a CEO and Co-founder at HoundBytes, bringing experience from previous roles at OWASP Foundation, ACP - Air Conditioning Products, CTF365 and Innovation Labs Romania.',
            linkedin: 'https://www.linkedin.com/in/mariuscorici/'
        },
        {
            name: 'Andrei Ghiciac',
            title: 'CISO & Co-Founder',
            image: 'images/andrei_ghiciac.png',
            description: 'Andrei leads the SOC team, providing top-tier threat monitoring and response. He supports business development with technical expertise, mentors the security team, and defines security strategies and procedures, ensuring robust protection and compliance for Houndbytes and its clients.',
            linkedin: 'https://www.linkedin.com/in/andrei-ghiciac-51105a261/'
        },
        {
            name: 'Bogdan Herea',
            title: 'Advisory Board',
            image: 'images/bogdan_herea.jpeg',
            description: 'Board Director & Advisor | Mission-driven Leadership | Business Coach | Tech Business Consultant. Serial Entrepreneur acting also as a business coach, providing mentorship to HoundBytes.',
            linkedin: 'https://www.linkedin.com/in/bogdanherea/'
        }
    ];

    return (
        <div className="middle-footer gradient-bottom-to-top team-full">
            <div className="container">
                <Grid fluid>
                    <Row>
                        <Col lg={8} className="team-header">
                            <h1>Meet our team</h1>
                        </Col>
                    </Row>

                    <Row className="team-row">
                        {teamMembers.map((member, index) => (
                            <Col lg={4} key={index} className="team-member__wrapper">
                                <div className="team-member">
                                    <Row className="team-member__image-wrapper">
                                        <img className="team-member__image" src={member.image} alt={member.name} />
                                    </Row>
                                    <Row className="team-member__name">
                                        <h4>{member.name}</h4>
                                    </Row>
                                    <Row className="team-member__title">
                                        <h4>{member.title}</h4>
                                    </Row>
                                    <Row className="team-member__description">
                                        <span>{member.description}</span>
                                    </Row>
                                    <Row className="team-member__link">
                                        <a href={member.linkedin} target="_blank" rel="noopener noreferrer">Linkedin</a>
                                    </Row>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default TeamFull;
