import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";

import {ReactComponent as Video1} from '../../svg/video.svg';

const Video = () => {
// adding video id
    const videoUrl = 'https://www.youtube.com/embed/fhwMO71GHyk';

    return (
        <div className="middle-footer gradient-bottom-to-top">
            <div className="container" >
                <Grid fluid>
                    <Row style={{paddingTop: "70px", paddingLeft: "14px"}}>
                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                    </Row>
                    <Row style={{paddingBottom: "60px", paddingLeft: "25px"}}>
                        <Col lg={4}>
                            <h1 style={{ paddingTop: "7%"}}>HoundBytes in <br/> 90 seconds</h1>
                            <p style={{ marginTop: "-10px"}}> Check out our video explainer.</p>
                        </Col>
                        <Col lg={8}>
			  <iframe
                                style={{ width: "100%", height: "500px" }} // Adjust the height as needed
                                src={videoUrl}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="HoundBytes Explainer"
                            ></iframe>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default Video;
