import React from 'react';
import { Col, Grid, Row } from "react-flexbox-grid";
import { useMediaQuery } from "react-responsive";
import "./FullFeatures.css";

// Import icons
import { ReactComponent as Icon1 } from '../../svg/icons/Icon1.svg';
import { ReactComponent as Icon2 } from '../../svg/icons/Icon2.svg';
import { ReactComponent as Icon3 } from '../../svg/icons/Icon3.svg';
import { ReactComponent as Icon4 } from '../../svg/icons/Icon4.svg';
import { ReactComponent as Icon5 } from '../../svg/icons/Icon5.svg';
import { ReactComponent as Icon6 } from '../../svg/icons/Icon6.svg';
import { ReactComponent as Icon7 } from '../../svg/icons/Icon7.svg';
import { ReactComponent as Icon8 } from '../../svg/icons/Icon8.svg';
import { ReactComponent as Icon9 } from '../../svg/icons/Icon9.svg';
import { ReactComponent as Icon10 } from '../../svg/icons/Icon10.svg';
import { ReactComponent as Icon11 } from '../../svg/icons/Icon11.svg';
import { ReactComponent as Icon12 } from '../../svg/icons/Icon12.svg';

const features = [
    {
        icon: <Icon1 style={{ marginTop: '10px' }} />,
        title: '24x7 CyberSecurity Monitoring',
        description:
            "Provides constant surveillance of the company's network and systems to identify and respond to potential threats in real-time. This includes monitoring for suspicious activity, such as unusual login attempts or changes to sensitive data, and alerting security personnel when potential threats are detected.",
    },
    {
        icon: <Icon2 style={{ marginTop: '10px' }} />,
        title: 'Threat Detection & Investigation assisted by ML',
        description:
            "We use machine learning algorithms to detect and investigate potential security threats automatically. By analyzing large amounts of data in real-time, the system can identify patterns or anomalies that may indicate the presence of a threat and alert the appropriate team for further investigation.",
    },
    {
        icon: <Icon3 style={{ marginTop: '10px' }} />,
        title: 'Incident Response',
        description:
            'In the event of a security incident, we use a structured and organized approach to respond to the situation. The process involves identifying the cause of the incident, containing and mitigating the damage, and restoring any affected systems or data.',
    },
    {
        icon: <Icon4 style={{ marginTop: '10px' }} />,
        title: 'Mitigation & Remediation & Host Isolation',
        description:
            'After a security incident has been identified and contained, the security team focuses on reducing the impact of the incident and preventing it from happening again. This includes implementing additional security measures, patching vulnerable systems, or isolating affected hosts to prevent the spread of the threat.',
    },
    {
        icon: <Icon5 style={{ marginTop: '10px' }} />,
        title: 'Reporting and Analytics',
        description:
            'We provide detailed reporting and analysis of security incidents and trends, helping your company understand the current state of its security posture and identify areas for improvement.',
    },
    {
        icon: <Icon6 style={{ marginTop: '10px' }} />,
        title: 'Threat Intelligence',
        description:
            'By collecting and analyzing intelligence about potential security threats, such as new vulnerabilities or emerging attack techniques, we help organizations stay ahead of potential threats and take proactive steps to protect their systems and data.',
    },
    {
        icon: <Icon7 style={{ marginTop: '10px' }} />,
        title: 'Notification and Alerting',
        description:
            'We ensure that the appropriate parties are notified in the event of a security incident. This includes sending alerts to designated individuals or teams or automatically triggering an incident response plan.',
    },
    {
        icon: <Icon8 style={{ marginTop: '10px' }} />,
        title: 'Insider Threat Detection',
        description:
            'Insider Threat Detection focuses on detecting and responding to threats that originate from within an organization, such as employees or contractors with malicious intent or compromised accounts. Our platform includes UBA (User Behavior Analytics).',
    },
    {
        icon: <Icon9 style={{ marginTop: '10px' }} />,
        title: 'Pro-Active Threat Hunting',
        description:
            'Our red team proactively searches for potential security threats that may not have been detected by other security measures. This involves using a combination of manual and automated techniques to identify and investigate potential threats.',
    },
    {
        icon: <Icon10 style={{ marginTop: '10px' }} />,
        title: 'Vulnerability Management',
        description:
            'This involves identifying, prioritizing, and mitigating vulnerabilities in the company’s systems and applications. This includes regularly scanning for vulnerabilities, applying patches or updates, and implementing additional security controls.',
    },
    {
        icon: <Icon11 style={{ marginTop: '10px' }} />,
        title: 'Continuous Security Posture',
        description:
            "Using our proprietary software SERP (Security Event and Response Platform), our clients won’t lose their security posture no matter what.",
    },
    {
        icon: <Icon12 style={{ marginTop: '10px' }} />,
        title: 'Custom Security Software Development',
        description:
            'We create unique software to meet specific security needs, including firewalls, intrusion detection, and encryption to protect against specific threats.',
    },
];

const FeatureCard = ({ icon, title, description, isLargeScreen }) => (
    <div className={`feature-card ${isLargeScreen ? "feature-card-wide" : ""}`}>
        <Row className="feature-content">
            <Col lg={12} className="feature-icon-wrapper">
                <div>{icon}</div>
            </Col>
            <Col lg={12} className="feature-text">
                <h3 className="feature-title">{title}</h3>
                <span className="feature-description">{description}</span>
            </Col>
        </Row>
    </div>
);

const FullFeatures = () => {
    const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' });

    return (
        <div className="full-features-section">
            <div className="container">
                <div className="full-features__cards">
                    {features.map(({ icon, title, description }, index) => (
                        <FeatureCard key={index} icon={icon} title={title} description={description} isLargeScreen={isLargeScreen} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FullFeatures;
