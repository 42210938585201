import React, {Fragment, useEffect} from 'react';
import Menu from "../components/menu/Menu";
import MenuBottom from "../components/menu/MenuBottom";
import Hero from "../components/hero/Hero";
import Mission from "../components/mission/Mission";
import LetsTalk from "../components/lets talk/LetsTalk";
import Video from "../components/video/video";
import KeyFeatures from "../components/key features/KeyFeatures";
import SideScroll from "../components/sidescroll/SideScroll";
import Footer from "../components/footer/Footer";

const Home = ({setMenuWhiteTheme}) => {

    useEffect(() => {
        setMenuWhiteTheme(true);
    }, []);

    return (
        <Fragment>
            <Hero/>
            <Mission/>
            <LetsTalk/>
            <Video/>
            <KeyFeatures/>
            <SideScroll/>
            <Footer/>
        </Fragment>
    );
};

export default Home;