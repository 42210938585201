import React, {Fragment} from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import BottomFooter from "./BottomFooter";
import MiddleFooter from "./MiddleFooter";
import StartFooter from "./StartFooter";

const Footer = () => {
    return (
       <Fragment>
           <StartFooter/>
           <MiddleFooter/>
           <BottomFooter/>
       </Fragment>
    );
};

export default Footer;