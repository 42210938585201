// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why-us-icons-row {
    padding-top: 50px;
    display: none;
}

@media (min-width: 992px) {
    .why-us-icons-row {
        display: flex;
    }
}

.icon-container:last-child svg {
    bottom: -30px
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 30px;
    text-align: center;
}

.icon-style {
    margin: 0 auto;
    position: relative;
}

.gradient-top-to-bottom {
    margin: 100px 0;
}

.why-us__feature-row {
    padding: 50px 14px 8px
}

.why-us__feature-container {
    margin: 10px;
    padding: 16px;
}

.why-us__feature-container h3 {
    margin-top: 0;
}

.why-us__feature-highlight {
    width: 30px;
    height: 10px;
    background-color: #BEB0FF;
    margin-bottom: 20px;
}

.why-us__feature-content__description {
    text-align: justify;
}

.why-us__feature-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.why-us__feature-content ul li {
    margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/whyusfull/WhyUsFull.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;AACJ;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".why-us-icons-row {\n    padding-top: 50px;\n    display: none;\n}\n\n@media (min-width: 992px) {\n    .why-us-icons-row {\n        display: flex;\n    }\n}\n\n.icon-container:last-child svg {\n    bottom: -30px\n}\n\n.icon-wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 10px;\n    padding: 30px;\n    text-align: center;\n}\n\n.icon-style {\n    margin: 0 auto;\n    position: relative;\n}\n\n.gradient-top-to-bottom {\n    margin: 100px 0;\n}\n\n.why-us__feature-row {\n    padding: 50px 14px 8px\n}\n\n.why-us__feature-container {\n    margin: 10px;\n    padding: 16px;\n}\n\n.why-us__feature-container h3 {\n    margin-top: 0;\n}\n\n.why-us__feature-highlight {\n    width: 30px;\n    height: 10px;\n    background-color: #BEB0FF;\n    margin-bottom: 20px;\n}\n\n.why-us__feature-content__description {\n    text-align: justify;\n}\n\n.why-us__feature-content ul {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n}\n\n.why-us__feature-content ul li {\n    margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
