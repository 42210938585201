import React, {useEffect} from 'react';
import Footer from "../components/footer/Footer";
import HeroContact from "../components/hero contact/HeroContact";
import ContactSections from "../components/contact_sections/ContactSections";

const Contact = ({ setMenuWhiteTheme}) => {

    useEffect(() => {
        setMenuWhiteTheme(false);
    }, []);

    return (
        <div>
            <HeroContact title="Contact" description="We are committed to sharing our knowledge, expertise, and experience free of charge" />
            {/*<ContactSections/>*/}
            <Footer/>
        </div>
    );
};

export default Contact;
