import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";


import {ReactComponent as Icon1} from '../../svg/icons/Icon1.svg';
import {ReactComponent as Icon2} from '../../svg/icons/Icon2.svg';
import {ReactComponent as Icon3} from '../../svg/icons/Icon3.svg';
import {ReactComponent as Icon4} from '../../svg/icons/Icon4.svg';
import {ReactComponent as Icon5} from '../../svg/icons/Icon5.svg';
import {ReactComponent as Icon6} from '../../svg/icons/Icon6.svg';
import {useMediaQuery} from "react-responsive";
import { Link } from 'react-router-dom'

const KeyFeatures = () => {


    const matches = useMediaQuery({ query: '(min-width: 992px)' });
    const matches1 = useMediaQuery({ query: '(min-width: 590px)' });

    return (
        <div className="middle-footer  gradient-bottom-to-top" style={{ backgroundColor: "gray"}}>
            <div className="container" >
                <Grid fluid>
                    <Row style={{paddingTop: "70px", paddingLeft: "14px"}}>
                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                    </Row>
                    <Row>
                        <Col lg={8}>
                            <h1 style={{paddingLeft: "25px"}}>Key Service Features</h1>
                        </Col>
                    </Row>



                    <Row style={{ paddingTop: "50px", paddingBottom: "10px" }}>
                        <Col lg={4}>
                            <div className="gradient-2"
                                 style={{margin: "10px", padding: "30px", height: matches1 ? "11vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <Col lg={4}>
                                        <Icon1 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={8}>
                                        <h3 >24x7 CyberSecurity Monitoring</h3>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="gradient-2"
                                 style={{margin: "10px", padding: "30px", height: matches1 ? "11vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <Col lg={4}>
                                        <Icon2 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={8}>
                                        <h3 >Threat Detection & Investigation assisted by ML</h3>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="gradient-2"
                                 style={{margin: "10px", padding: "30px", height: matches1 ? "11vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <Col lg={4}>
                                        <Icon3 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={8}>
                                        <h3 >Incident Response</h3>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: "0px", paddingBottom: "50px" }}>
                        <Col lg={4}>
                            <div className="gradient-2"
                                 style={{margin: "10px", padding: "30px", height: matches1 ? "11vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <Col lg={4}>
                                        <Icon4 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={8}>
                                        <h3 >Mitigation & Remediation & host isolation</h3>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="gradient-2"
                                 style={{margin: "10px", padding: "30px", height: matches1 ? "11vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <Col lg={4}>
                                        <Icon5 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={8}>
                                        <h3 >Reporting and Analytics</h3>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="gradient-2"
                                 style={{margin: "10px", padding: "30px", height: matches1 ? "11vh" : "auto"}}>
                                <Row style={{ textAlign: matches ? "left" : "center"}}>
                                    <Col lg={4}>
                                        <Icon6 style={{ marginTop: "10px"}}/>
                                    </Col>
                                    <Col lg={8}>
                                        <h3 >Threat Intelligence</h3>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: "0px", paddingBottom: "80px", marginLeft: "10px"}}>
                        <Link to={"/key_features"}>
                            <button className={"action-button"}> DISCOVER ALL FEATURES </button>
                        </Link>
                    </Row>

                </Grid>
            </div>
        </div>
    );
};

export default KeyFeatures;
