import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import mainLogo from '../../images/logo.png';
import { useMediaQuery } from 'react-responsive'
import {Link} from "react-router-dom";


const BottomFooter = () => {

    const matches = useMediaQuery({ query: '(min-width: 992px)' });

    return (
        <div className="bottom-footer gradient-left-to-right">
            <div className="container" >
                <Grid fluid>
                    <Row style={{ paddingTop: "30px", paddingBottom: "30px"}}>
                        <Col lg={4} >
                            <img src={mainLogo} alt="Logo" style={{ maxWidth: matches ? "80%" : "70%", maxHeight: matches ? "80%" : "70%", top: "20%", position: "relative"}} />
                        </Col>
                        <Col lg={8} style={{ textAlign: matches ? "right" : "left"}}>
                            <div style={{ position: "relative", top: "30%", paddingBottom: matches ? "0px": "100px"}}>
                                <span>Copyright © HoundBytes 2024  &nbsp; | &nbsp; </span>
                                <Link to="/privacy"> Privacy Policy   </Link>
                                {/*<a href={""} target={"_blank"}> &nbsp; | &nbsp; Terms & Conditions </a>*/}
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default BottomFooter;
