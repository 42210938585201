// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certified-quality {

}

.certified-quality hr {
    border-color: #BEB0FF;
    border-width: 0 0 1px 0;
}

.certified-quality .badge-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 32px;
    gap: 16px;
}

.badge-wrapper.badge-wrapper--iso svg {
    max-height: 92px;
    height: auto;
}

.badge-wrapper.badge-wrapper--industry > * {
    max-height: 92px;
    width: auto;
    max-width: 33%;
}
@media (max-width: 991px) {
    .certified-quality .badge-wrapper {
        flex-wrap: wrap;
    }
    .badge-wrapper.badge-wrapper--iso svg {
        max-height: 200px;
        width: 50%;
        max-width: 250px;
    }

    .badge-wrapper.badge-wrapper--industry > * {
        max-height: 200px;
        width: auto;
        max-width: 40%;
    }
}
@media (max-width: 589px) {
    .badge-wrapper.badge-wrapper--industry > *,
    .badge-wrapper.badge-wrapper--iso svg {
        max-height: 140px;
        width: auto;
        max-width: 260px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/StartFooter.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,cAAc;AAClB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,iBAAiB;QACjB,UAAU;QACV,gBAAgB;IACpB;;IAEA;QACI,iBAAiB;QACjB,WAAW;QACX,cAAc;IAClB;AACJ;AACA;IACI;;QAEI,iBAAiB;QACjB,WAAW;QACX,gBAAgB;IACpB;AACJ","sourcesContent":[".certified-quality {\n\n}\n\n.certified-quality hr {\n    border-color: #BEB0FF;\n    border-width: 0 0 1px 0;\n}\n\n.certified-quality .badge-wrapper {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    margin-top: 32px;\n    gap: 16px;\n}\n\n.badge-wrapper.badge-wrapper--iso svg {\n    max-height: 92px;\n    height: auto;\n}\n\n.badge-wrapper.badge-wrapper--industry > * {\n    max-height: 92px;\n    width: auto;\n    max-width: 33%;\n}\n@media (max-width: 991px) {\n    .certified-quality .badge-wrapper {\n        flex-wrap: wrap;\n    }\n    .badge-wrapper.badge-wrapper--iso svg {\n        max-height: 200px;\n        width: 50%;\n        max-width: 250px;\n    }\n\n    .badge-wrapper.badge-wrapper--industry > * {\n        max-height: 200px;\n        width: auto;\n        max-width: 40%;\n    }\n}\n@media (max-width: 589px) {\n    .badge-wrapper.badge-wrapper--industry > *,\n    .badge-wrapper.badge-wrapper--iso svg {\n        max-height: 140px;\n        width: auto;\n        max-width: 260px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
