import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import Groups2Icon from '@mui/icons-material/Groups2';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import ExploreIcon from '@mui/icons-material/Explore';
import {useMediaQuery} from "react-responsive";
import {Link, useLocation} from "react-router-dom";

const MenuBottom = () => {

    const [value, setValue] = React.useState(0);

    const matches = useMediaQuery({ query: '(min-width: 992px)' });


    const location = useLocation();

    useEffect(() => {
        // Determine the current path and set the default value
        switch (location.pathname) {
            case '/':
                setValue(0);
                break;
            case '/key_features':
                setValue(1);
                break;
            case '/why_us':
                setValue(2);
                break;
            case '/team':
                setValue(3);
                break;
            case '/contact':
                setValue(4);
                break;
            default:
                setValue(0); // Default to "Home" if path is unknown
        }
    }, [location.pathname]); // Update whenever the pathname changes

    return (
        <BottomNavigation
            showLabels
            value={value}
            style={{ position: "fixed", bottom: "0", width: "100%", visibility: matches ? "hidden" : "visible", textAlign: "center", zIndex: "1000"}}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
        >
            <BottomNavigationAction label="Home" icon={<HomeIcon />} index={0}  component={Link} to={"/"} />
            <BottomNavigationAction label="Key Features" icon={<WorkspacesIcon/>} index={1}  component={Link} to={"/key_features"} />
            <BottomNavigationAction label="Why us" icon={<ExploreIcon />}  index={2} component={Link} to={"/why_us"} />
            <BottomNavigationAction label="Team" icon={<Groups2Icon />} index={3} component={Link} to={"/team"} />
            <BottomNavigationAction label="Contact" icon={<MessageIcon />} index={4} component={Link} to={"/contact"} />
        </BottomNavigation>
    );
};

export default MenuBottom;