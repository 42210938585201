// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-features-section {
    /*background-color: gray;*/
}

.feature-row {
    padding-top: 50px;
    padding-bottom: 50px;
}

.feature-row:first-child {
    /*padding-top: 100px;*/
}

.feature-row:last-child {
    /*padding-bottom: 160px;*/
}

.feature-card {
    /*margin: 10px;*/
    /*padding: 30px;*/
    /*height: auto;*/
    /*text-align: center;*/
}

.feature-card-wide {
    /*height: 20vh;*/
}

.feature-icon-wrapper {
    text-align: center;
    /*margin-top: 10px;*/
}

.feature-icon {
    margin-top: 10px;
}

.feature-title {
    text-align: center;
    /*font-size: 1.5em;*/
    /*font-weight: bold;*/
}

.feature-description {
    display: block;
    margin-top: 10px;
    font-size: 1em;
    color: #333;
}
.full-features__cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;
    padding: 50px 16px 100px;
}
/* Media Queries */

@media (min-width: 768px) {
    .full-features__cards {
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width: 992px) {
    .full-features__cards {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 100px 60px;
    }
    .feature-content {
        text-align: justify;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/full_features/FullFeatures.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,WAAW;AACf;AACA;IACI,aAAa;IACb,0BAA0B;IAC1B,SAAS;IACT,wBAAwB;AAC5B;AACA,kBAAkB;;AAElB;IACI;QACI,8BAA8B;IAClC;AACJ;AACA;IACI;QACI,kCAAkC;QAClC,eAAe;IACnB;IACA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".full-features-section {\n    /*background-color: gray;*/\n}\n\n.feature-row {\n    padding-top: 50px;\n    padding-bottom: 50px;\n}\n\n.feature-row:first-child {\n    /*padding-top: 100px;*/\n}\n\n.feature-row:last-child {\n    /*padding-bottom: 160px;*/\n}\n\n.feature-card {\n    /*margin: 10px;*/\n    /*padding: 30px;*/\n    /*height: auto;*/\n    /*text-align: center;*/\n}\n\n.feature-card-wide {\n    /*height: 20vh;*/\n}\n\n.feature-icon-wrapper {\n    text-align: center;\n    /*margin-top: 10px;*/\n}\n\n.feature-icon {\n    margin-top: 10px;\n}\n\n.feature-title {\n    text-align: center;\n    /*font-size: 1.5em;*/\n    /*font-weight: bold;*/\n}\n\n.feature-description {\n    display: block;\n    margin-top: 10px;\n    font-size: 1em;\n    color: #333;\n}\n.full-features__cards {\n    display: grid;\n    grid-template-columns: 1fr;\n    gap: 60px;\n    padding: 50px 16px 100px;\n}\n/* Media Queries */\n\n@media (min-width: 768px) {\n    .full-features__cards {\n        grid-template-columns: 1fr 1fr;\n    }\n}\n@media (min-width: 992px) {\n    .full-features__cards {\n        grid-template-columns: 1fr 1fr 1fr;\n        gap: 100px 60px;\n    }\n    .feature-content {\n        text-align: justify;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
