// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero {
    width: 100%;
    height: 80vh;
}

.awssld__controls {
    opacity: 0;
}

.awssld__bullets button {
    border-radius: 0 !important;
    height: 8px;
    width: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/hero/Hero.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,2BAA2B;IAC3B,WAAW;IACX,WAAW;AACf","sourcesContent":[".hero {\n    width: 100%;\n    height: 80vh;\n}\n\n.awssld__controls {\n    opacity: 0;\n}\n\n.awssld__bullets button {\n    border-radius: 0 !important;\n    height: 8px;\n    width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
