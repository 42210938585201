import React from 'react';
import {Col, Grid, Row} from "react-flexbox-grid";
import {ReactComponent as Badge1} from '../../svg/badge1.svg';
import {ReactComponent as Badge2} from '../../svg/badge2.svg';
import {useMediaQuery} from "react-responsive";
import './StartFooter.css';

const StartFooter = () => {

    const matches = useMediaQuery({ query: '(min-width: 992px)' });

    return (
        <div className="bottom-footer gradient-left-to-right certified-quality">
            <div className="container" >
                <Grid fluid>
                    <Row style={{ paddingTop: "50px"}}>
                        <div style={{ width: "30px", height: "10px", backgroundColor: "#BEB0FF"}}></div>
                    </Row>
                    <Row style={{ paddingBottom: "32px"}}>
                        <Col lg={12} style={{textAlign: matches ? "left" : "center", paddingLeft: matches ? "30px" : "0px"}}>
                            <h1>Certified quality </h1>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "0px", paddingBottom: "50px"}}>
                        <Col sm={12} md={12} lg={4} style={{textAlign: matches ? "left" : "center", paddingLeft: matches ? "30px" : "0px"}}>
                            <p style={{ marginTop: "-10px", textAlign: "center"}}> We are ISO 9001/27001 certified.</p>
                            <hr/>
                            <div className="badge-wrapper badge-wrapper--iso">
                                <Badge1 />
                                <Badge2 />
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={7} lgOffset={1} style={{ textAlign: matches ? "end" : "center", marginTop: matches ? "0px" : "60px"}}>
                            <p style={{ marginTop: "-10px", textAlign: "center"}}> We are trusted by industry leaders</p>
                            <hr/>
                            <div className="badge-wrapper badge-wrapper--industry">
                                <img src={'images/elastic.png'} alt={'Elastic Partner logo'} />
                                <img src={'images/microsoft.png'} alt={'Microsoft Partner logo'} />
                                <a target="_blank" href="https://discovery.ariba.com/profile/AN11208191322" rel="noopener noreferrer">
                                    <img src={'images/sap.png'} alt={'SAP Business Network logo'} />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default StartFooter;