import React, {useEffect} from 'react';
import HeroImage from "../components/hero image/HeroImage";
import Footer from "../components/footer/Footer";
import TeamFull from "../components/team/TeamFull";
import TeamImage from '../images/team-hero.webp'


const Team = ({setMenuWhiteTheme}) => {

    useEffect(() => {
        setMenuWhiteTheme(true);
    }, []);

    return (
        <div>
            <HeroImage title="" description="Team" backgroundImage={TeamImage}/>
            <TeamFull/>
            <Footer/>
        </div>
    );
};

export default Team;
